import clsx from 'clsx';
import { ReactElement } from 'react';
import { svgTwitter } from '@/assets/icons';
import { Icon, Link, Text } from '@/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { PageType } from '@/types/page';
import { Mobile, Tablet } from '@/utils/responsive';
import { useCookieConsent } from '@/providers';
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_USE_URL,
  TWITTER_URL,
} from '@/types/routes';
import { BackedBy, ContactSection } from './components';

export type FooterProps = StyleProps & {
  /**
   * The page type to determine the style of the footer.
   */
  pageType?: PageType;
};

const ROOT = makeRootClassName('Footer');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

const TWITTER_ICON_VIEWBOX_WIDTH = 24;

const InfoLinks = ({
  size,
}: {
  size: 'small' | 'xs' | 'medium' | 'large' | undefined;
}) => {
  const { actions } = useCookieConsent();

  return (
    <div className={el`info`}>
      <Link variant="white" size={size} isExternal href={TERMS_OF_USE_URL}>
        Terms of Use
      </Link>
      <Link variant="white" size={size} isExternal href={PRIVACY_POLICY_URL}>
        Privacy Policy
      </Link>
      <Link
        variant="white"
        size={size}
        onPress={() => actions.setIsAccepted(false)}
      >
        Cookie Policy
      </Link>
    </div>
  );
};

function Footer(props: FooterProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const isHomePage = p.pageType === PageType.HOME;
  const isAboutPage = p.pageType === PageType.ABOUT;

  return (
    <>
      <Tablet>
        {!isAboutPage && <ContactSection />}
        {!isHomePage && <BackedBy />}
      </Tablet>
      <Mobile>
        <ContactSection />
        {!isHomePage && !isAboutPage && <BackedBy />}
      </Mobile>
      <footer className={clsx([ROOT, p.className, { 'is-home': isHomePage }])}>
        <div className={el`content`}>
          <div className={el`social-links`}>
            <a
              href={TWITTER_URL}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Join chainabuse twitter community"
            >
              <Icon
                content={svgTwitter}
                size="custom"
                viewBoxWidth={TWITTER_ICON_VIEWBOX_WIDTH}
                className={el`twitter-icon`}
              />
            </a>
            <Text>Join the community</Text>
          </div>

          <div className={el`info-wrapper`}>
            <Mobile>
              <InfoLinks size="large" />
            </Mobile>
            <Tablet>
              <InfoLinks size="xs" />
            </Tablet>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
